/* COMMON */

html
  font-size 62.5%
  scroll-behavior: smooth;

body
  color #212020
  font-size countFontSize(18)
  line-height: 1.17
  font-weight 400
  min-width 320px
  height 100%
  font-family $fontRaleway
    
.cursor-wait
  cursor wait

.noscript
  position fixed
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  padding 0 2rem
  line-height 1.2
  background-color #fff
  z-index 999

a
  transition: color .32s

a:hover
  @media (hover: hover) and (min-width: $widthMobile)
    color #FA1919





/* POPUP */

.popup
  display: none
  position fixed
  left 0
  top 0
  right 0
  bottom 0

  &__wrap
    display flex
    justify-content center
    align-items center
    width: 100%
    height: 100%

  &__box
    background #fff
    border 1px solid #bbb
    border-radius 1.5rem
    padding 1.5rem 2.5rem
    text-align: center
    line-height: 1.4





/* HEADER */

.header
  display: flex
  position: relative;
  width: 100%;
  z-index 9
  font-family $fontTTCommons
  font-size countFontSize(28)
  
  @media (min-width: 1080px)
    font-size countFontSize(17)

  &__box
    margin 0 auto
    display flex
    z-index 1
    width 100%
    max-width 164rem
    align-items: center
    border-bottom 1px solid #999
    padding: countVW(3) 8rem countVW(3) 2rem

    @media (min-width: 1080px)
      padding: countVW(3) 2rem

  &__logo
    margin-right: auto

  &__nav
    width: 100%;
    display flex
    align-items: center;
    transition: .35s all;
    z-index 1

    @media (min-width: 1080px)
      padding: 0 countVW(17) 0 0
      max-width: countVW(555)

  &__contacts
    display: flex
    gap 0 countVW(23)
    padding: 0 countVW(28) 0 countVW(17)

  &__icons
    display: flex
    justify-content: space-between
    width: 14rem

    @media (min-width: 420px)
      width: 18.5rem

    @media (min-width: $widthMobile)
      width: 21rem

  &__search
    padding: 0 0 0 countVW(15)

  &__toggle
    position: absolute
    left: 0
    top 0
    transform: translate(0,-100%)
    transition: all .32s
    background: #fff
    width 100%
    z-index: 1;
    display: flex
    gap: countVW(42) 2.5rem;
    flex-direction: column
    align-items: center
    padding: 2.5rem 0
    border-bottom .1rem solid #aea9a9

    @media (min-width: 1080px)
      position: relative
      left: auto
      top auto
      width auto
      transform: none
      margin: 0 auto
      flex-direction: row
      border-bottom none

    &.open
      transform: translate(0,0)

      @media (min-width: 1080px)
        transform: none

  &__linkToggle
    position absolute
    width 4rem
    height 3rem
    right: 2rem;
    top: 50%
    transform: translate(0,-50%)
    z-index 2
    
    @media (min-width: 1080px)
      display none
    
    &:before,
    &:after
      content ""
    
    &:before,
    &:after,
    span
      position absolute
      height .4rem
      width 100%
      background #000
      left 0
      border-radius .2rem
      transition .3s all
      
    &:before
      top 0
      
    &:after
      bottom 0

    span
      top 50%
      left 50%
      transform translate(-50%, -50%)
      
    &.open span
      opacity 0
      width 0
      
    &.open:before
      transform rotate(-45deg) translate(-0.55rem, 1.3rem)
      
    &.open:after
      transform rotate(45deg) translate(-.55rem, -1.25rem)

  &__whatsapp
    display: none

    @media (min-width: 1080px)
      display: block

.linkIcon
  position: relative
  width: 3.2rem
  flex-shrink: 0

  @media (min-width: 420px)
    width: 3.8rem

  @media (min-width: $widthMobile)
    width 4.5rem
    height 4.5rem
    /* width countVW(38) */

  /* @media (min-width: 1420px)
    width auto */

  &:before
    content ""
    position absolute
    left 1px
    top 1px
    bottom 1px
    right 1px
    border-radius 50%
    opacity 0
    transition all .35s;
    z-index -1
    background: #F1F2FF

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      opacity: 1

  &--whatsapp
    @media (min-width: 1080px)
      display: none

  &--footer:before
    opacity: 1

  &--vk:before
    background #d1e7ff

  &--yt:before
    background #ffcfcf

  &--ig:before
    background #ffeaaf

  &--wa:before
    background #d4ffda


.contact
  display: flex
  gap 0 countVW(5)
  white-space: nowrap
  font-weight: 600
  line-height: 1.2;

  &--header &__label
    display: none

    @media (min-width: 1420px)
      display: inherit

  &__value
    font-weight: 500
    display: flex
    gap countVW(5)

  &__phoneImg
    height: 1.5rem;

.logo
  display block
  text-align: center
  width 9rem

  @media (min-width: $widthMobile)
    width 12rem

.nav
  display flex
  gap 1.5rem countVW(27)
  line-height: 1.2
  flex-direction: column
  align-items: center
  width: 100%;

  @media (min-width: 1080px)
    flex-direction: row
    width auto

  &__link
    padding countVW(20) 0
    white-space: nowrap
    font-weight: 600
    text-transform: uppercase

  &__linkInner
    padding: countVW(8)
    font-size: countFontSize(20)
    font-weight: 400
    
    @media (min-width: 1080px)
      font-size: countFontSize(14)

  &__linkWrap
    position relative
    text-align: center;

  &__linkWrap:hover &__box
    @media (hover: hover) and (min-width: $widthMobile)
      display: flex

  &__box
    display: flex
    flex-direction: column
    text-align: left
    align-items: center
    text-align: center;

    @media (min-width: 1080px)
      position: absolute
      top: calc(100% + 1rem)
      left: countVW(-15)
      display: none
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      width: countVW(273)
      padding: countVW(14) countVW(7) countVW(18)
      background: #fff
      border: 1px solid #E7E7E7;

    &:before

      @media (min-width: 1080px)
        content: ""
        position: absolute
        left: 4.2rem
        top: -.8rem
        background: #fff
        width: 1.5rem
        height: 1.5rem
        border: 1px solid #E7E7E7;
        border-bottom: 0
        border-left: 0
        transform rotate(-45deg)

.formSearch
  width: countVW(280)
  height countVW(45)
  position: relative

  @media (min-width: 1080px)
    width: countVW(156)

  @media (min-width: 1420px)
    width: countVW(316)

  &__input
    padding: .4rem 0 0 4.6rem
    border: 1px solid #999;
    width: 100%
    height: 100%
    border-radius: 2.2rem

  &__submit
    position: absolute
    left: 1.7rem
    top 50%
    transform: translate(0,-50%)
    width 2.1rem
    height 2.1rem
    border none
    background: url(../images/icon_search.svg) no-repeat 0 0

.linkPurple
  font-weight 600
  position: relative
  text-transform: uppercase

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      color #212020

  &:before,
  &:after
    content ""
    position absolute
    width: countVW(98)
    height: countVW(98)
    left -1.4rem
    top 50%
    z-index: -1;
    transform translate(0, -50%)
    border-radius: 50%
    background: #F1F2FF

  &:after
    opacity 0
    transition: opacity .32s
    background: linear-gradient(146.94deg, #FFFFFF 2.7%, #CDD1FF 95.98%);

  &:hover:after
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1

  &--big
    &:before,
    &:after
      width countVW(215)
      height countVW(215)
      background #d2d5ff

    
.linkArr
  display: flex
  align-items: center

  &__img
    width countVW(40)
    margin-left countVW(10)

.titlePink
  position relative
  font-size countFontSize(48)
  padding-left: countVW(30);
  text-align: left
  
  &:before
    content ""
    position absolute
    transform: rotate(-21.22deg);
    border: 4px solid #FCC7C7;
    width: countVW(222)
    height: countVW(99)
    z-index -1
    border-radius: 50%
    left: 0
    bottom: countVW(-10)

    @media (min-width: $widthMobile)
      width: countVW(272)
      height: countVW(119)


/* FOOTER */


.footer
  font-family $fontTTCommons
  border-top 1px solid #212020;

  &__topLine
    display flex
    flex-wrap wrap
    gap countVW(28) 0 
    margin 0 auto
    max-width 168rem
    padding countVW(74) 2rem countVW(28)
    flex-direction column

    @media (min-width: 680px)
      flex-direction: row
      gap countVW(28) 2% 

    @media (min-width: $widthMobile)
      justify-content: space-between
      gap countVW(28) countVW(28) 
      flex-wrap: nowrap

  &__soc
    order 3

    @media (min-width: 680px)
      width 49%

    @media (min-width: $widthMobile)
      max-width 22rem
      width auto
      order inherit
      width 100%

    @media (min-width: 1180px)
      max-width 39rem

  &__pay
    display flex
    align-items center
    gap 0 countVW(28)

  &__contacts
    display flex
    flex-direction column
    gap countVW(9) 0
    justify-content center
    order 2

    @media (min-width: 680px)
      width 49%

    @media (min-width: $widthMobile)
      width auto
      order inherit
      justify-content flex-start

  &__work
    order 1

    @media (min-width: 680px)
      width 49%

    @media (min-width: $widthMobile)
      width auto
      order inherit

  &__text
    font-size countFontSize(12)
    padding-top countVW(10)

  &__last
    max-width 44rem
    order 4

    @media (min-width: $widthMobile)
      order inherit

.work
  font-weight 500

  &__label
    font-weight 600

  &__text
    white-space nowrap

.soc
  font-weight 600
  display flex
  gap 0 countVW(16)

  &__label
    display none

    @media (min-width: 1180px)
      display block

    @media (min-width: 1420px)
      margin-right auto 

.studio
  background rgba(255, 207, 207, 0.27)
  height countVW(60)
  display flex
  align-items center
  justify-content center
  font-size countFontSize(14)
  font-weight 600

  &:hover &__img
    @media (hover: hover) and (min-width: $widthMobile)
      transform rotate(360deg)

  &__img
    width countVW(32)
    margin 0 countVW(9)
    transition all .35s


.wrap
  position relative
/* 
  &:before
    content: ""
    position: absolute
    left: 0
    width: 100%
    bottom: 0
    height: countVW(1600)
    z-index: -1
    background: #F8F8F8 url(../images/bg_gray.jpg) no-repeat center top
 */
/* CONTENT */

.content
  position: relative
  padding: 2.7rem 0 12rem

  &__wrap
    max-width: 168rem
    padding 0 2rem
    width 100%
    margin: 0 auto

  &__breadcrumb
    padding: countVW(12) 2rem countVW(120)

  &__columns
    display: flex
    justify-content: center
    flex-wrap: wrap
    border-top: 1px solid #AEA9A9;
    margin: countVW(96) 0 0
    padding: countVW(50) 0 0
    gap 0 10%

  &__column
    width: 100%

    @media (min-width: 840px)
      width: 45%

    &--100
      @media (min-width: 840px)
        width: 100%

  &__caption
    font-size: countFontSize(28)
    text-transform: uppercase
    margin: 0 0 countVW(34)
    font-family $fontTTCommons

    &--pink
      display: inline-block
      background: rgba(255, 207, 207, 0.3);
      border-radius: countVW(34)
      padding: countVW(8) countVW(10) countVW(1)

  &__text
    font-size: countFontSize(20)
    line-height: 1.4
    padding: 0 0 countVW(72)
    display: flex
    flex-direction: column
    gap countVW(28) 0
    font-family $fontTTCommons

    &--pink
      border-left 3px solid #FCC7C7
      padding: countVW(18) 0 countVW(18) countVW(15)
      margin: 0 0 countVW(34)
  
    iframe
      width: 100%
      margin: 0 0 countVW(46)

    ol
      list-style-type decimal
      list-style-position inside
    
    ul li
      position relative
      padding-left 1.6rem

      &:before
        content ""
        position: absolute
        border-radius: 50%
        width .4rem
        height .4rem
        left 0
        top .7rem
        background #212020

    ol,
    ul
      display: flex
      flex-direction: column
      gap countVW(13) 0

.breadcrumb
  &__item
    &:nth-child(n+2)
      position: relative
      padding: 0 0 0 countVW(26)

      @media (min-width: $widthMobile)
        padding: 0 0 0 countVW(18)

    &:nth-child(n+2):before
      content: ""
      display: inline-block
      position: relative
      left: countVW(-9)
      transform translate(0,-20%) rotate(45deg)
      border .1rem solid #212020
      border-width: .1rem .1rem 0 0
      width: .4rem
      height: .4rem
      margin-bottom: .2rem


.introduce

  &__wrap
    position: relative
    padding 0

    @media (min-width: $widthMobile)
      padding: 0 2rem;

  &__left
    width: 100%
    opacity: .65
    position relative

    @media (min-width: $widthMobile)
      width: countVW(680)
      opacity 1

    @media (min-width: 1200px)
      width: countVW(820)

    /*&:before
      content ""
      position: absolute
      width: 100%
      height: 100%
      top 0
      right 0
      background: linear-gradient(90deg, transparent 0%, rgba(255,255,255,1) 100%);*/

  &__title
    position: absolute
    font-size countFontSize(82)
    left: 4%;
    right 4%;
    top countVW(25)
    text-transform: uppercase
    text-shadow: 0 0 .8rem #fff;
    z-index 1

    @media (min-width: 480px)
      right auto

    @media (min-width: $widthMobile)
      width countVW(1000)
      font-size countFontSize(62)

    @media (min-width: 1200px)
      left: 28.5%;

  &__text
    position: absolute
    left 4%
    right 4%
    top countVW(240)
    font-size countFontSize(44)
    text-shadow: 0 0 .8rem #fff;
    z-index 1
    font-weight: 600;

    @media (min-width: 440px)
      top countVW(340)


    @media (min-width: $widthMobile)
      width: countVW(400)
      left 51.5%
      right auto
      top countVW(210)
      font-size countFontSize(24)
      font-weight: 400;

  &__link
    position absolute
    left 8%
    top countVW(485)
    z-index 1
    font-size countFontSize(52)

    @media (min-width: 440px)
      top countVW(585)

    @media (min-width: $widthMobile)
      font-weight 700
      font-size countFontSize(18)
      top countVW(485)
      font-size inherit
      left 50.7%

  &__right
    position: absolute
    top countVW(220)
    left 72.5%
    padding: 0 countVW(27) 0 0
    display none

    @media (min-width: $widthMobile)
      display block

    &:before
      content: ""
      position: absolute
      left: countVW(26)
      top countVW(-22)
      bottom countVW(-22)
      border: 2px solid rgba(0, 0, 0, 0.12);
      border-radius: 50%
      right: 0
      z-index -1

  &__rightImg
    border-radius: countVW(300)
    max-height: countVW(550) 

.imgStar
  position relative

  &:before
    content: ""
    position: absolute
    background: url(../images/icon_star.svg) no-repeat 0 0
    background-size: contain;
    width countVW(196)
    height: countVW(189)

  &--topPage:before
    right countVW(30)
    top countVW(-85)

    @media (min-width: $widthMobile)
      top countVW(-125)

.description
  padding: countVW(58) 0 countVW(148)

  @media (min-width: $widthMobile)
    padding: countVW(58) 0 countVW(211)

  &__wrap
    display flex
    flex-wrap: wrap
    justify-content: space-between

    @media (min-width: $widthMobile)
      gap 0 2%

    @media (min-width: 1200px)
      padding-right: countVW(180)

  &__title
    font-size countFontSize(36)
    font-weight: 700;
    width 100%
    text-transform: uppercase

  &__text
    position relative
    width 100%
    display: flex
    flex-direction: column
    gap countVW(28) 0
    line-height: 1.35

    @media (min-width: 760)
      width 49%

    @media (min-width: $widthMobile)
      width countVW(581)

    &:nth-child(2)
      padding: countVW(40) 0 0

    &:nth-child(3)
      padding: countVW(20) 0 0

      &:before
        content: ""
        position: absolute
        left: countVW(-156)
        top countVW(-46)
        background: rgba(255, 207, 207, 0.27);
        width countVW(460)
        height: countVW(460)
        border-radius: 50%
        z-index: -1;

  &__box
    padding: 0 0 countVW(50)

    @media (min-width: $widthMobile)
      padding: countVW(9) 0 0 countVW(100)

    &:before
      left: 0
      top countVW(-10)
      transform: translate(-50%,-50%)

  &__link
    margin: countVW(64) 0 0 countVW(17)

.catalog
  padding 0 0 countVW(110)

  @media (min-width: 1480px)
    padding 0 0 countVW(180)

  &--inner
    padding countVW(30) 0 countVW(110)

  &__wrap
    text-align: center;

  &__box
    padding countVW(80) 0 countVW(80)
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly
    gap: countVW(49) countVW(18)

    @media (min-width: $widthMobile)
      padding countVW(80) 0 countVW(100)
      justify-content: space-between

    &:after
      content: "";
      height: 0;
      width: 100%
      max-width: countVW(340)

    &--inner
      padding countVW(80) 0

      @media (min-width: $widthMobile)
        padding countVW(80) 0

  &__linkShowAll
    span
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 9px solid #212020
      margin-left: 1rem
      transform: translate(0,-15%);

.category
  border-radius: 1.5rem;
  border: 1px solid #BBBBBB;
  text-align: center
  width: 100%
  display: flex
  flex-direction: column
  padding: 1.2rem
  text-transform: uppercase
  font-weight: 600
  font-family $fontTTCommons

  @media (min-width: 480px)
    width: countVW(340)

  &__text
    height: countVW(76)

  &__imgWrap
    display: flex
    align-items: center;
    justify-content: center
    height: countVW(285)
    padding: 0 0 countVW(15)

  &__img
    max-height: 100%

  &__small
    display block
    font-size countFontSize(12)
    font-weight 400

.solutions
  padding countVW(110) 0 0

  &__slider
    padding countVW(80) 0 0

    @media (min-width: 820px)
      padding countVW(140) 0 0

.sliderSolutions
  position: relative;

  &__box
    position: relative;
    overflow: hidden;
    touch-action: pan-y;

    @media (min-width: 1280px)
      border-radius: countVW(300)

  &__inner
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    transform: translate3d(0px,0,0);
    cursor e-resize

  &__item
    flex-shrink: 0;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column
    justify-content: center;
    align-items: center;
    
  &__btn
    position: absolute
    width: 3.3rem
    height: 3.3rem
    top: 50%
    z-index: 2
    background: url(../images/icon_arrow.svg) no-repeat 0 0

    &--left
      left 0
      transform: translate(0, -50%)

    &--right
      right 0
      transform: translate(0, -50%) rotate(180deg)

  &__img
    height: countVW(313)
    border-radius: countVW(150) countVW(150) 0 0
  
  &__item:nth-child(even) &__img
    border-radius: countVW(156)

  &__label
    padding: countVW(26) 0 0
    text-transform: uppercase

.feedback
  padding: countVW(180) 0 0

  &__wrap
    display flex
    justify-content: space-between
    gap countVW(30)
    flex-direction: column

    @media (min-width: 640px)
      flex-direction: row

  &__column
    &:nth-child(1)
      @media (min-width: 640px)
        width countVW(465)

    &:nth-child(2)
      @media (min-width: 640px)
        width countVW(400)
        margin: 0 auto

    &:nth-child(3)
      display: none

      @media (min-width: 840px)
        display: block

  &__title,
  &__text
    font-weight: 700
    line-height: 1.6
    text-transform: uppercase

  &__title
    font-size countFontSize(28)

    &--pink
      position relative

      &:before
        content: ""
        position: absolute
        z-index: -1
        border-radius: 0 countVW(300) countVW(300) 0
        background: rgba(255, 207, 207, 0.27);
        right: 0
        top 0
        bottom: 0
        left: 0
        margin: countVW(-50) countVW(-14) countVW(-10) countVW(-20)

        @media (min-width: 640px)
          margin: countVW(-32) countVW(-20)

        @media (min-width: $widthMobile)
          left: countVW(-268)
          margin: countVW(-32)

        @media (min-width: 1280)
          margin: countVW(-68)

  &__text
    padding: 0 0 countVW(28)

  &__img
    width countVW(397)
    border-radius: countVW(280)

.form
  &__input,
  &__textarea
    background: none
    width: 100%
    padding: countVW(12) countVW(2)
    border: none
    border-bottom: 1px solid #212020;
    margin: 0 0 countVW(34)
    font-size countFontSize(16)

  &__textarea
    min-height: countVW(108)
    max-width: 100%
    margin: 0 0 countVW(72)

  &__submit
    background: none
    border: none
    font-weight: 700
    font-size countFontSize(20)
    color: #212020;
    transition: opacity .32s

    &.disabled
      opacity: .35;
      cursor default

  &__loader,
  &__answer
    display none

.cart
  padding 0 0 countVW(82)

  &__items
    padding: countVW(88) 0 countVW(54)
    font-family $fontTTCommons

  &__item
    display: flex
    border: 1px solid #AEA9A9;
    padding: countVW(26) countVW(20)
    min-height: countVW(225)
    gap 0 countVW(25)
    flex-wrap wrap
    
    @media (min-width: 480px)
      flex-wrap nowrap

    @media (min-width: 680px)
      padding: countVW(26) countVW(50) countVW(26) countVW(20)

    &:nth-child(n+2)
      border-top 0

  &__column
    display flex
    align-content flex-start
    
    &:nth-child(1)
      justify-content center
      width 100%

      @media (min-width: 480px)
        width 16.5%
        flex-shrink 0

    &:nth-child(2)
      flex-direction column
      gap countVW(8) countVW(22)
      width 86%

      @media (min-width: 480px)
        width auto

      @media (min-width: 680px)
        flex-direction row
        flex-wrap wrap

    &:nth-child(3)
      margin-left: auto

  &__label
    text-transform: uppercase
    font-size countFontSize(28)
    font-weight: 500;
    width 100%
    padding: 0 0 countVW(8)

    @media (min-width: 680px)
      padding: countVW(9) 0 countVW(40)

  &__color,
  &__count,
  &__price
    display flex
    align-items: center;
    gap 0 countVW(12)
    font-size countFontSize(24)

    @media (min-width: 680px)
      font-size countFontSize(18)

  &__attr
    display: flex;
    justify-content: center;
    padding countVW(2) 0 0
    width: 25.5%;
    gap countVW(8)
    flex-direction: column

  &__count
    width 35.5%

  &__countLabel
    padding countVW(2) 0 0

  &__countInput
    border: 1px solid #AEA9A9;
    border-radius: 30px;
    padding countVW(5) countVW(14) 0
    height 3.6rem
    width 8rem

  &__price
    padding countVW(20) 0 0
    font-weight 500

    @media (min-width: 680px)
      padding countVW(2) 0 0

    &Value
      color: #FA1919;
      font-weight: 500;

  &__delete
    display: block;
    position: relative
    width: 2rem
    height: 2rem

    @media (min-width: 680px)
      margin: countVW(11) 0 0;

    &:before,
    &:after
      content: ""
      position: absolute
      width 100%
      height: .01rem
      background: rgba(0,0,0,.7)
      margin: 50% 0 0;
      transition all .32s

    &:before
      left 0
      transform rotate(45deg)

    &:after
      right 0
      transform rotate(-45deg)

    &:hover
      background none !important

    &:hover:before,
    &:hover:after
      background: #fa1919

  &__bottomBox
    display flex
    align-items: flex-start;
    flex-wrap wrap
    gap countVW(44) countVW(20)

  &__linkCatalog
    position relative
    padding 0 0 0 countVW(32)
    order 2

    @media (min-width: 480px)
      order 0

    &:before
      content ""
      position absolute
      width countVW(9)
      height countVW(9)
      top 50%
      left 0
      transform rotate(45deg) translate(0, -50%)
      border .1rem solid #000
      border-width 0 0 .1rem .1rem
      
  &__itog
    width 100%
    font-weight: 500;
    display flex
    flex-wrap wrap
    margin-left auto

    @media (min-width: 480px)
      max-width 22.5rem

    @media (min-width: $widthMobile)
      max-width 36.5rem

  &__itogValue,
  &__itogLabel
    font-family $fontTTCommons
    font-size countFontSize(24)

  &__itogValue
    color: #FA1919;
    margin-left auto

  &__itogBreak
    height .1rem
    width 100%
    background #000
    margin countVW(16) 0 countVW(29)

  &__linkOffer
    display flex
    justify-content center
    background: #F1F2FF;
    height countVW(55)
    border-radius: 3rem;
    font-weight 600
    width 100%
    padding countVW(15) 0 0

  &__img
    margin 0 0 countVW(30)
    object-fit: contain;
    max-height: 14rem

    @media (min-width: 480px)
      margin 0
      max-height: none

.recommend
  padding countVW(48) 0 0

  &__title
    font-family $fontTTCommons
    font-size countFontSize(28)
    font-weight: 500;

  &__items
    padding countVW(32) 0 0
    display flex
    justify-content space-evenly
    flex-wrap wrap
    gap countVW(30) countVW(30)

    @media (min-width: 740px)
      justify-content space-between
    
    &:after
      content: "";
      height: 0;
      width: 100%
      max-width: countVW(304)

.product
  width 100%
  border: 1px solid #BBB;
  border-radius: 1.5rem;
  padding 1.4rem
  text-align center

  @media (min-width: 480px)
    max-width countVW(304)

  &__imgWrap
    display flex
    justify-content center
    height countVW(235)
    padding countVW(8) 0 countVW(10)

  &__label
    font-family $fontTTCommons
    font-size countFontSize(16)
    min-height countVW(50)
    padding countVW(4) countVW(11) countVW(11)

  &__price
    font-family $fontTTCommons
    font-weight 500
    margin 0 0 countVW(14)
    text-align center
    color #FA1919

  &__addCart
    display flex
    justify-content center
    background: #F1F2FF;
    border-radius: 3rem;
    height 3.6rem
    padding .8rem 0 0
    font-size countFontSize(16)
    font-weight 600
    width 100%
    max-width 15.5rem
    margin 0 auto

.good
  &__columns
    display flex
    gap countVW(15)
    flex-direction column

    @media (min-width: 480px)
      flex-direction row

  &__topBox
    padding 0 0 countVW(120)

    @media (min-width: 480px)
      display: grid
      grid-template-columns 25% 75%

    @media (min-width: 880px)
      grid-template-columns countVW(435) auto

  &__box2
    padding:0 0 countVW(35)

    @media (min-width: 480px)
      padding:0 countVW(25) 0 0
      grid-row 1/3

  &__imgWrap
    display inline-flex
    justify-content center
    border: 1px solid #B1B1B1;
    padding countVW(30) countVW(50)

  &__label
    font-family $fontTTCommons
    font-weight 600
    font-size countFontSize(34)
    padding 0 0 countVW(45)

    @media (min-width: 480px)
      font-size countFontSize(28)
      font-weight 500

  &__spec
    display flex
    justify-content space-between
    flex-wrap wrap
    font-family $fontTTCommons
    gap countVW(20) 0
    padding 0 0 countVW(58)

    @media (min-width: 480px)
      max-width countVW(720)
      /* padding 0 0 countVW(110) */

  &__specItem
    width 50%
    max-width countVW(350)
    padding 0 countVW(20) 0 0

  &__price
    font-family $fontTTCommons
    font-weight 500
    font-size countFontSize(28)
    color: #FA1919;
    padding 0 0 countVW(14)

  &__bottomLine
    display flex
    flex-direction column
    gap countVW(30) 0

    @media (min-width: 480px)
      flex-direction row

  &__count
    display flex
    gap 0 countVW(24)
    width 100%
    max-width countVW(370)

  &__countLabel
    font-family $fontTTCommons
    padding .8rem 0 0

  &__countInput
    font-family $fontTTCommons
    border: 1px solid #AEA9A9;
    border-radius: 3rem;
    padding .3rem 1.4rem 0
    height 3.6rem
    width 8rem

  &__addCart
    display block
    border-radius 3rem
    height 3.6rem
    padding .8rem 0 0
    text-align center
    width 15.5rem
    background: #F1F2FF;
    font-weight 600

  &__bottomBox
    display flex
    gap countVW(66)
    justify-content space-between
    flex-direction column

    @media (min-width: 880px)
      flex-direction row

  &__tabs
    border: 1px solid #AEA9A9;
    padding 0 countVW(15) countVW(36)
    width 100%

    @media (min-width: 480px)
      padding 0 countVW(58) countVW(36)

    @media (min-width: 880px)
      width 59.35%

  &__tabsTopBox
    display flex

  &__tabsBtn
    padding countVW(15) countVW(34)
    transition all .32s

    &.open
      cursor default
      color #212020
      background: rgba(252, 199, 199, 0.3);

  &__text
    display none
    flex-direction column
    gap countVW(30) 0
    font-family $fontTTCommons
    font-size countFontSize(16)
    line-height 1.44
    width 100%
    padding countVW(24) 0 0

    @media (min-width: 880px)
      max-width countVW(734)

    &.open
      display flex

  &__follow
    width 100%

    @media (min-width: 880px)
      width 37%

.follow
  display flex
  gap countVW(8)
  flex-direction column

  &__title
    text-transform uppercase
    padding 0 0 countVW(20)
    font-weight 700

  &__item
    border: .1rem solid #BBB;
    border-radius: 1.5rem;
    padding 1rem 2.5rem 1rem 1rem
    width 100%
    display flex
    gap countVW(25)

  &__column
    &:nth-child(1)
      width 100%
      max-width countVW(245)
      max-height countVW(155)
      display: flex;
      justify-content: center;

  &__img
    max-height: 100%;
    object-fit: contain;

  &__label
    display block
    padding countVW(20) 0 countVW(15)
    font-family $fontTTCommons
    font-size countFontSize(16)

  &__price
    font-family $fontTTCommons
    font-weight 500
    color: #FA1919;
    padding 0 0 countVW(14)

  &__addCard
    display block
    border-radius 3rem
    height 3.6rem
    padding .7rem 0 0
    text-align center
    width 15.5rem
    background: #F1F2FF;
    font-weight 600

.products

  &__columns
    border-top: 1px solid #999999;
    margin countVW(64) 0 0
    padding countVW(28) 0 0
    display: flex
    flex-direction column
    gap countVW(28)

    @media (min-width: 680px)
      flex-direction row
      height: 19rem;

    @media (min-width: 880px)
      height: 24rem;

    &.open
      height auto

  &__filters
    width 100%
    max-width: 36.5rem
    order 2

    @media (min-width: 680px)
      order 1

  &__orders
    order 1

    @media (min-width: 680px)
      margin-left: auto
      order 2

  &__list
    padding countVW(32) 0 0
    display flex
    justify-content space-evenly
    flex-wrap wrap
    gap countVW(30) countVW(30)

    @media (min-width: 740px)
      justify-content space-between
    
    &:after
      content: "";
      height: 0;
      width: 100%
      max-width: countVW(304)

  &__pages
    ul
      display flex
      align-items center
      flex-wrap wrap
      gap .9rem
      font-family $fontTTCommons
      font-size countFontSize(16)
      padding countVW(24) 0 0 !important
      margin 0 !important

      @media (min-width: $widthMobile)
        padding countVW(74) 0 0 !important

      li
        display flex
        align-items center
        justify-content center

      .page-numbers
        border-radius 3rem
        border .1rem solid #D3D3D3
        width 2.6rem
        height 2.6rem
        padding .5rem .6rem 0
        text-align center
        
        &.current
          background #F1F2FF


  //-.page-numbers

.pages
  display flex
  align-items center
  flex-wrap wrap
  gap .9rem
  font-family $fontTTCommons
  font-size countFontSize(16)
  padding countVW(24) 0 0

  @media (min-width: $widthMobile)
    padding countVW(74) 0 0

  &__num
    border-radius: 3rem
    border: .1rem solid #D3D3D3;
    width: 2.6rem
    height: 2.6rem
    display: flex
    align-items: center
    justify-content: center
    padding: .3rem .6rem 0

    &.active
      background: #F1F2FF;

  &__prev,
  &__next
    width .9rem
    height .9rem
    border .1rem solid #212020
    transform rotate(45deg)
    margin-top: -.1rem;
    transition: all .32s

    &:hover
      border-color: #fa1919;

  &__prev
    border-width 0 0 .1rem .1rem

  &__next
    border-width .1rem .1rem 0 0

.order
  display flex
  gap 0 1.2rem
  align-items: center
  font-family $fontTTCommons

  &__label
    padding: .4rem 0 0

  &__select
    position: relative;
    height 3.5rem
    cursor: pointer;
    border: .1rem solid #ddd;
    border-radius: 3rem;
    appearance: none;
    font-family $fontTTCommons
    padding: .5rem 1rem 0
    text-align: center
    min-width 15.5rem

  &__form
    margin 0 !important
    float none !important

.page
  &__box
    border-top: 1px solid #AEA9A9;
    margin: countVW(96) 0 0
    padding: countVW(50) 0 0

  &__text
    max-width: 96rem
    margin: 0 auto

.contacts
  &__columns
    gap countVW(64) 10%

    @media (min-width: 840px)
      gap 0 10%

  &__text
    font-size countFontSize(22)

    @media (min-width: 840px)
      min-height: countVW(192)

  &__address,
  &__phoneWrap,
  &__phone
    display: flex
    gap countVW(12)

  &__address
    align-items: flex-start;

  &__phone
    gap countVW(8)

  &__phoneImg,
  &__addressImg
    @media (min-width: 840px)
      margin: .3rem 0 0

  &__phoneImg
    width: 1.5rem
    height: 1.5rem

    @media (min-width: 840px)
      width: 2.2rem
      height: 2.2rem

  &__iframe
    width: 100%

.news
  &__items
    display flex
    flex-wrap wrap
    margin countVW(96) 0 0
    padding countVW(80) 0
    border-top 1px solid #aea9a9
    justify-content center
    gap 5.5rem 2rem

    @media (min-width: 1240px)
      justify-content space-between

    &:after
      content ""

  &__items:after,
  &__item
    width 100%
    display flex
    flex-direction column

    @media (min-width: 580px)
      width countVW(340)

  &__item
    &ImgWrap
      display flex
      align-items center
      justify-content center
      padding 0 0 countVW(15)

      @media (min-width: 580px)
        height countVW(220)

    &Img
      max-height 100%

    &Title
      font-weight 600
      font-family $fontTTCommons
      font-size countFontSize(26)

    &Text
      font-size countFontSize(14)
      padding countVW(8) 0

    &Date
      color #7a7a7a
      font-size countFontSize(12)

.whatsapp
  display: block
  width: 3rem
  height: 3rem
  margin: -.2rem 0 0;
  transition: all .32s

  &:hover
    transform scale(1.2)

.desktopOnly
  display none

  @media (min-width: $widthMobile)
    display block

.filters
  border 1px solid #BBBBBB
  box-shadow 0 .4rem .4rem rgba(0, 0, 0, 0.25)
  border-radius 1.5rem
  padding 1.2rem 1.4rem 0 1.4rem
  font-family $fontTTCommons
  position relative
  background #fff
  z-index 1
  position relative
  overflow hidden
  height 21rem
  transition height .32s

  &.open
    height: auto

  &__title,
  &__label
    font-weight: 500
    padding: 0 0 countVW(8)

  &__crop
    display: none
    overflow: hidden

    &Box
      position absolute
      bottom 0
      left 1rem
      right 1rem
      text-align center
      padding 1rem
      background #fff

    &Link
      position: relative
      padding: 0 1.5rem

      &:before
        content: ""
        position: absolute
        right: 0
        top: 50%
        transition: all .35s
        transform translate(0, -50%)
        width: 0;
        height: 0;
        border-left: .4rem solid transparent;
        border-right: .4rem solid transparent;
        border-top: .6rem solid #212020;

      &.open:before
        transform translate(0, -50%) rotate(180deg)
  /*
  &__box
    padding: 0 0 countVW(21)

  &__price
    display flex
    justify-content: space-between
    gap 0 10%

  &__priceInput
    border-radius: 3rem;
    border: .1rem solid #BBB;
    padding .2rem 1.1rem 0
    width 45%
    height: 3.5rem
    
  &__item
    display: flex
    align-items: center
    transition: all .32s

    &:hover
      color #fa1919

  &__checkInput,
  &__checkLabel
    cursor pointer

  &__checkInput
    border: .1rem solid #ADADAD;
    width: 1.1rem
    height: 1.1rem

  &__checkLabel
    padding .3rem 0 0 1.2rem

  &__buttons
    display flex
    justify-content: space-between
    padding 0 0 countVW(24)
    gap 10%

  &__button
    border-radius: 3rem;
    width 45%
    max-width 15.5rem
    height 3.6rem
    display: flex
    justify-content: center
    align-items: center
    padding: .3rem 0 0

    &--show
      background: #F1F2FF;

    &--reset
      border 1px solid #AEA9A9
      background #AEA9A9
      color #fff
      transition: all .32s

      &:hover
        background #fff
        color #AEA9A9

  &__slider
    position: relative
    top: 1.8rem
    height: 2.2rem

  &__sliderLineGray,
  &__sliderLineActive
    position: absolute
    height: .2rem
    top: .4rem

  &__sliderLineGray
    width 100%
    background: #bbb

  &__sliderLineActive
    width 80%
    background: #FCC7C7

    &:before,
    &:after
      content: ""
      position: absolute
      width 1.1rem
      height 1.1rem
      border-radius: 50%
      top: 50%
      transform translate(0,-50%)
      background: #FCC7C7
      cursor: pointer

    &:before
      left 0

    &:after
      right 0
  */
.img-loader
  stroke #545454

.checkout input,
.checkout textarea,
.woocommerce form .form-row input.input-text, 
.woocommerce form .form-row textarea
  border 1px solid #aaa
  border-radius 4px
  padding .6rem .6rem .2rem

.woocommerce form .show-password-input,
.woocommerce-page form .show-password-input
  top 50% !important
  transform translate(0,-50%) !important

.woocommerce form .show-password-input::after,
.woocommerce-page form .show-password-input::after
  line-height 2

.woocommerce fieldset
  border 1px solid #aaa
  border-radius 1.5rem
  padding 1.2rem
  margin-bottom 2.5rem

.select2-container--default .select2-selection--single .select2-selection__rendered
  border-radius 1.5rem

.woof
  h4
    font-weight 500
    padding: 0 0 countVW(8)

.woof_sid_auto_shortcode
  border none !important
  box-shadow none !important
  background none !important
  margin 0 !important
  padding 0 !important

  .woof_container
    width 100% !important
    padding: 0 0 countVW(21) !important
    float none !important
    margin 0 !important

  .woof_container.woof_price_filter
    padding: 0 0 countVW(45) !important

  .woof_container_inner
    padding 0 !important

.woocommerce .widget_price_filter .ui-slider-horizontal
  height: 2px !important

.woocommerce .widget_price_filter .price_slider
  margin-bottom 0 !important
  
.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content
  background-color #bbb !important

.woocommerce .widget_price_filter .ui-slider .ui-slider-range
  background-color #FCC7C7 !important
  border-radius 0 !important

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle
  background-color #FCC7C7 !important
  border-radius 50% !important
  width 1.1rem !important
  height 1.1rem !important
  border none !important

.woocommerce .widget_price_filter .ui-slider
  margin-top .8rem !important
  margin-bottom .4rem !important

.woocommerce .widget_price_filter .price_slider_amount
  position relative

.woof_price_search_container .price_slider_amount .price_label
  font-size 0
  width 100%
  position: absolute;
  left: 0;
  top: 0;

  span
    font-size countFontSize(18)
  
  .to
    float right

.woof .woof_list li
  margin 0 !important
  padding 0 !important

.woof_list label
  padding-bottom 0 !important

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woof_reset_button_2
  border-radius 3rem !important
  width 15.5rem !important
  height 3.6rem !important
  display flex !important
  justify-content: center !important
  align-items: center !important
  padding .3rem 0 0 !important
  border 1px solid #AEA9A9 !important
  background #AEA9A9 !important
  color #fff !important
  transition: all .32s !important

  &:hover
    background #fff !important
    color #AEA9A9 !important

.woof_reset_button_2
  font-weight: 600;
  padding: 0 !important;

.woof .widget_price_filter .price_slider_amount button.button
  float left !important
  margin 4rem 0 0 !important
  background: #F1F2FF !important
  color #000 !important
  font-weight: 400;
  
.woof_submit_search_form_container
  margin 0 0 3rem !important

.woof_products_top_panel_ul
  display none
/*li:nth-child(n+2)
    display none

.woof_products_top_panel
  margin 2.5rem 0 0 !important
  */

.woocommerce-message
  display flex
  flex-direction column
  gap 1rem
  border 1px solid #b1b1b1

.cart .woocommerce-info,
.cart .woocommerce-message,
.products .woocommerce-message
  margin-top 4.5rem !important

.woocommerce-info,
.good .woocommerce-message
  margin-bottom 4.5rem !important
  background rgba(241,242,255,.5)
  border 1px solid #b1b1b1

.woocommerce a.button.wc-backward
  display none !important

.woocommerce a.button.wc-forward
  width 22rem !important
  order 1

.woocommerce-thankyou-order-received
  font-size countFontSize(28)

.woocommerce ul.order_details li
  font-size countFontSize(16) !important

.woocommerce ul.order_details li:before
  display none