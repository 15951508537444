@font-face {
  font-family: 'Raleway';
  src: local('Raleway Regular'), local('Raleway-Regular'), url("../fonts/ralewayregular.woff2") format('woff2'), url("../fonts/ralewayregular.woff") format('woff'), url("../fonts/ralewayregular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url("../fonts/ralewaysemibold.woff2") format('woff2'), url("../fonts/ralewaysemibold.woff") format('woff'), url("../fonts/ralewaysemibold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: local('Raleway Bold'), local('Raleway-Bold'), url("../fonts/ralewaybold.woff2") format('woff2'), url("../fonts/ralewaybold.woff") format('woff'), url("../fonts/ralewaybold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'TTCommons';
  src: url("../fonts/TTCommons-Regular.woff") format('woff'), url("../fonts/TTCommons-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'TTCommons';
  src: url("../fonts/TTCommons-Medium.woff") format('woff'), url("../fonts/TTCommons-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'TTCommons';
  src: url("../fonts/TTCommons-SemiBold.woff") format('woff'), url("../fonts/TTCommons-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong {
  font-weight: 600;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  color: #212020;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  line-height: 1.17;
  font-weight: 400;
  min-width: 320px;
  height: 100%;
  font-family: Raleway, Arial, sans-serif;
}
.cursor-wait {
  cursor: wait;
}
.noscript {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  line-height: 1.2;
  background-color: #fff;
  z-index: 999;
}
a {
  transition: color 0.32s;
}
@media (hover: hover) and (min-width: 992px) {
  a:hover {
    color: #fa1919;
  }
}
.popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.popup__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.popup__box {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 1.5rem;
  padding: 1.5rem 2.5rem;
  text-align: center;
  line-height: 1.4;
}
.header {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 9;
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
}
@media (min-width: 1080px) {
  .header {
    font-size: min(calc(1.4rem + 0.16vw), 1.7rem);
  }
}
.header__box {
  margin: 0 auto;
  display: flex;
  z-index: 1;
  width: 100%;
  max-width: 164rem;
  align-items: center;
  border-bottom: 1px solid #999;
  padding: min(calc(0.15rem + 0.08vw), 0.3rem) 8rem min(calc(0.15rem + 0.08vw), 0.3rem) 2rem;
}
@media (min-width: 1080px) {
  .header__box {
    padding: min(calc(0.15rem + 0.08vw), 0.3rem) 2rem;
  }
}
.header__logo {
  margin-right: auto;
}
.header__nav {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.35s all;
  z-index: 1;
}
@media (min-width: 1080px) {
  .header__nav {
    padding: 0 min(calc(0.85rem + 0.44vw), 1.7rem) 0 0;
    max-width: min(calc(27.75rem + 14.45vw), 55.5rem);
  }
}
.header__contacts {
  display: flex;
  gap: 0 min(calc(1.15rem + 0.6vw), 2.3rem);
  padding: 0 min(calc(1.4rem + 0.73vw), 2.8rem) 0 min(calc(0.85rem + 0.44vw), 1.7rem);
}
.header__icons {
  display: flex;
  justify-content: space-between;
  width: 14rem;
}
@media (min-width: 420px) {
  .header__icons {
    width: 18.5rem;
  }
}
@media (min-width: 992px) {
  .header__icons {
    width: 21rem;
  }
}
.header__search {
  padding: 0 0 0 min(calc(0.75rem + 0.39vw), 1.5rem);
}
.header__toggle {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0, -100%);
  transition: all 0.32s;
  background: #fff;
  width: 100%;
  z-index: 1;
  display: flex;
  gap: min(calc(2.1rem + 1.09vw), 4.2rem) 2.5rem;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
  border-bottom: 0.1rem solid #aea9a9;
}
@media (min-width: 1080px) {
  .header__toggle {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    transform: none;
    margin: 0 auto;
    flex-direction: row;
    border-bottom: none;
  }
}
.header__toggle.open {
  transform: translate(0, 0);
}
@media (min-width: 1080px) {
  .header__toggle.open {
    transform: none;
  }
}
.header__linkToggle {
  position: absolute;
  width: 4rem;
  height: 3rem;
  right: 2rem;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}
@media (min-width: 1080px) {
  .header__linkToggle {
    display: none;
  }
}
.header__linkToggle:before,
.header__linkToggle:after {
  content: "";
}
.header__linkToggle:before,
.header__linkToggle:after,
.header__linkToggle span {
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background: #000;
  left: 0;
  border-radius: 0.2rem;
  transition: 0.3s all;
}
.header__linkToggle:before {
  top: 0;
}
.header__linkToggle:after {
  bottom: 0;
}
.header__linkToggle span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__linkToggle.open span {
  opacity: 0;
  width: 0;
}
.header__linkToggle.open:before {
  transform: rotate(-45deg) translate(-0.55rem, 1.3rem);
}
.header__linkToggle.open:after {
  transform: rotate(45deg) translate(-0.55rem, -1.25rem);
}
.header__whatsapp {
  display: none;
}
@media (min-width: 1080px) {
  .header__whatsapp {
    display: block;
  }
}
.linkIcon {
  position: relative;
  width: 3.2rem;
  flex-shrink: 0;
/* @media (min-width: 1420px)
    width auto */
}
@media (min-width: 420px) {
  .linkIcon {
    width: 3.8rem;
  }
}
@media (min-width: 992px) {
  .linkIcon {
    width: 4.5rem;
    height: 4.5rem;
  }
}
.linkIcon:before {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.35s;
  z-index: -1;
  background: #f1f2ff;
}
@media (hover: hover) and (min-width: 992px) {
  .linkIcon:hover:before {
    opacity: 1;
  }
}
@media (min-width: 1080px) {
  .linkIcon--whatsapp {
    display: none;
  }
}
.linkIcon--footer:before {
  opacity: 1;
}
.linkIcon--vk:before {
  background: #d1e7ff;
}
.linkIcon--yt:before {
  background: #ffcfcf;
}
.linkIcon--ig:before {
  background: #ffeaaf;
}
.linkIcon--wa:before {
  background: #d4ffda;
}
.contact {
  display: flex;
  gap: 0 min(calc(0.25rem + 0.13vw), 0.5rem);
  white-space: nowrap;
  font-weight: 600;
  line-height: 1.2;
}
.contact--header .contact__label {
  display: none;
}
@media (min-width: 1420px) {
  .contact--header .contact__label {
    display: inherit;
  }
}
.contact__value {
  font-weight: 500;
  display: flex;
  gap: min(calc(0.25rem + 0.13vw), 0.5rem);
}
.contact__phoneImg {
  height: 1.5rem;
}
.logo {
  display: block;
  text-align: center;
  width: 9rem;
}
@media (min-width: 992px) {
  .logo {
    width: 12rem;
  }
}
.nav {
  display: flex;
  gap: 1.5rem min(calc(1.35rem + 0.7vw), 2.7rem);
  line-height: 1.2;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (min-width: 1080px) {
  .nav {
    flex-direction: row;
    width: auto;
  }
}
.nav__link {
  padding: min(calc(1rem + 0.52vw), 2rem) 0;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
}
.nav__linkInner {
  padding: min(calc(0.4rem + 0.21vw), 0.8rem);
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  font-weight: 400;
}
@media (min-width: 1080px) {
  .nav__linkInner {
    font-size: min(calc(1.4rem + 0vw), 1.4rem);
  }
}
.nav__linkWrap {
  position: relative;
  text-align: center;
}
@media (hover: hover) and (min-width: 992px) {
  .nav__linkWrap:hover .nav__box {
    display: flex;
  }
}
.nav__box {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  text-align: center;
}
@media (min-width: 1080px) {
  .nav__box {
    position: absolute;
    top: calc(100% + 1rem);
    left: min(calc(-0.75rem + -0.39vw), -1.5rem);
    display: none;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    width: min(calc(13.65rem + 7.11vw), 27.3rem);
    padding: min(calc(0.7rem + 0.36vw), 1.4rem) min(calc(0.35rem + 0.18vw), 0.7rem) min(calc(0.9rem + 0.47vw), 1.8rem);
    background: #fff;
    border: 1px solid #e7e7e7;
  }
}
@media (min-width: 1080px) {
  .nav__box:before {
    content: "";
    position: absolute;
    left: 4.2rem;
    top: -0.8rem;
    background: #fff;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #e7e7e7;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-45deg);
  }
}
.formSearch {
  width: min(calc(14rem + 7.29vw), 28rem);
  height: min(calc(2.25rem + 1.17vw), 4.5rem);
  position: relative;
}
@media (min-width: 1080px) {
  .formSearch {
    width: min(calc(7.8rem + 4.06vw), 15.6rem);
  }
}
@media (min-width: 1420px) {
  .formSearch {
    width: min(calc(15.8rem + 8.23vw), 31.6rem);
  }
}
.formSearch__input {
  padding: 0.4rem 0 0 4.6rem;
  border: 1px solid #999;
  width: 100%;
  height: 100%;
  border-radius: 2.2rem;
}
.formSearch__submit {
  position: absolute;
  left: 1.7rem;
  top: 50%;
  transform: translate(0, -50%);
  width: 2.1rem;
  height: 2.1rem;
  border: none;
  background: url("../images/icon_search.svg") no-repeat 0 0;
}
.linkPurple {
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
}
@media (hover: hover) and (min-width: 992px) {
  .linkPurple:hover {
    color: #212020;
  }
}
.linkPurple:before,
.linkPurple:after {
  content: "";
  position: absolute;
  width: min(calc(4.9rem + 2.55vw), 9.8rem);
  height: min(calc(4.9rem + 2.55vw), 9.8rem);
  left: -1.4rem;
  top: 50%;
  z-index: -1;
  transform: translate(0, -50%);
  border-radius: 50%;
  background: #f1f2ff;
}
.linkPurple:after {
  opacity: 0;
  transition: opacity 0.32s;
  background: linear-gradient(146.94deg, #fff 2.7%, #cdd1ff 95.98%);
}
@media (hover: hover) and (min-width: 992px) {
  .linkPurple:hover:after {
    opacity: 1;
  }
}
.linkPurple--big:before,
.linkPurple--big:after {
  width: min(calc(10.75rem + 5.6vw), 21.5rem);
  height: min(calc(10.75rem + 5.6vw), 21.5rem);
  background: #d2d5ff;
}
.linkArr {
  display: flex;
  align-items: center;
}
.linkArr__img {
  width: min(calc(2rem + 1.04vw), 4rem);
  margin-left: min(calc(0.5rem + 0.26vw), 1rem);
}
.titlePink {
  position: relative;
  font-size: min(calc(1.4rem + 1.77vw), 4.8rem);
  padding-left: min(calc(1.5rem + 0.78vw), 3rem);
  text-align: left;
}
.titlePink:before {
  content: "";
  position: absolute;
  transform: rotate(-21.22deg);
  border: 4px solid #fcc7c7;
  width: min(calc(11.1rem + 5.78vw), 22.2rem);
  height: min(calc(4.95rem + 2.58vw), 9.9rem);
  z-index: -1;
  border-radius: 50%;
  left: 0;
  bottom: min(calc(-0.5rem + -0.26vw), -1rem);
}
@media (min-width: 992px) {
  .titlePink:before {
    width: min(calc(13.6rem + 7.08vw), 27.2rem);
    height: min(calc(5.95rem + 3.1vw), 11.9rem);
  }
}
.footer {
  font-family: TTCommons, Arial, sans-serif;
  border-top: 1px solid #212020;
}
.footer__topLine {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem) 0;
  margin: 0 auto;
  max-width: 168rem;
  padding: min(calc(3.7rem + 1.93vw), 7.4rem) 2rem min(calc(1.4rem + 0.73vw), 2.8rem);
  flex-direction: column;
}
@media (min-width: 680px) {
  .footer__topLine {
    flex-direction: row;
    gap: min(calc(1.4rem + 0.73vw), 2.8rem) 2%;
  }
}
@media (min-width: 992px) {
  .footer__topLine {
    justify-content: space-between;
    gap: min(calc(1.4rem + 0.73vw), 2.8rem) min(calc(1.4rem + 0.73vw), 2.8rem);
    flex-wrap: nowrap;
  }
}
.footer__soc {
  order: 3;
}
@media (min-width: 680px) {
  .footer__soc {
    width: 49%;
  }
}
@media (min-width: 992px) {
  .footer__soc {
    max-width: 22rem;
    width: auto;
    order: inherit;
    width: 100%;
  }
}
@media (min-width: 1180px) {
  .footer__soc {
    max-width: 39rem;
  }
}
.footer__pay {
  display: flex;
  align-items: center;
  gap: 0 min(calc(1.4rem + 0.73vw), 2.8rem);
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.45rem + 0.23vw), 0.9rem) 0;
  justify-content: center;
  order: 2;
}
@media (min-width: 680px) {
  .footer__contacts {
    width: 49%;
  }
}
@media (min-width: 992px) {
  .footer__contacts {
    width: auto;
    order: inherit;
    justify-content: flex-start;
  }
}
.footer__work {
  order: 1;
}
@media (min-width: 680px) {
  .footer__work {
    width: 49%;
  }
}
@media (min-width: 992px) {
  .footer__work {
    width: auto;
    order: inherit;
  }
}
.footer__text {
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  padding-top: min(calc(0.5rem + 0.26vw), 1rem);
}
.footer__last {
  max-width: 44rem;
  order: 4;
}
@media (min-width: 992px) {
  .footer__last {
    order: inherit;
  }
}
.work {
  font-weight: 500;
}
.work__label {
  font-weight: 600;
}
.work__text {
  white-space: nowrap;
}
.soc {
  font-weight: 600;
  display: flex;
  gap: 0 min(calc(0.8rem + 0.42vw), 1.6rem);
}
.soc__label {
  display: none;
}
@media (min-width: 1180px) {
  .soc__label {
    display: block;
  }
}
@media (min-width: 1420px) {
  .soc__label {
    margin-right: auto;
  }
}
.studio {
  background: rgba(255,207,207,0.27);
  height: min(calc(3rem + 1.56vw), 6rem);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  font-weight: 600;
}
@media (hover: hover) and (min-width: 992px) {
  .studio:hover .studio__img {
    transform: rotate(360deg);
  }
}
.studio__img {
  width: min(calc(1.6rem + 0.83vw), 3.2rem);
  margin: 0 min(calc(0.45rem + 0.23vw), 0.9rem);
  transition: all 0.35s;
}
.wrap {
  position: relative;
}
.content {
  position: relative;
  padding: 2.7rem 0 12rem;
}
.content__wrap {
  max-width: 168rem;
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
}
.content__breadcrumb {
  padding: min(calc(0.6rem + 0.31vw), 1.2rem) 2rem min(calc(6rem + 3.13vw), 12rem);
}
.content__columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid #aea9a9;
  margin: min(calc(4.8rem + 2.5vw), 9.6rem) 0 0;
  padding: min(calc(2.5rem + 1.3vw), 5rem) 0 0;
  gap: 0 10%;
}
.content__column {
  width: 100%;
}
@media (min-width: 840px) {
  .content__column {
    width: 45%;
  }
}
@media (min-width: 840px) {
  .content__column--100 {
    width: 100%;
  }
}
.content__caption {
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  text-transform: uppercase;
  margin: 0 0 min(calc(1.7rem + 0.89vw), 3.4rem);
  font-family: TTCommons, Arial, sans-serif;
}
.content__caption--pink {
  display: inline-block;
  background: rgba(255,207,207,0.3);
  border-radius: min(calc(1.7rem + 0.89vw), 3.4rem);
  padding: min(calc(0.4rem + 0.21vw), 0.8rem) min(calc(0.5rem + 0.26vw), 1rem) min(calc(0.05rem + 0.03vw), 0.1rem);
}
.content__text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  line-height: 1.4;
  padding: 0 0 min(calc(3.6rem + 1.88vw), 7.2rem);
  display: flex;
  flex-direction: column;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem) 0;
  font-family: TTCommons, Arial, sans-serif;
}
.content__text--pink {
  border-left: 3px solid #fcc7c7;
  padding: min(calc(0.9rem + 0.47vw), 1.8rem) 0 min(calc(0.9rem + 0.47vw), 1.8rem) min(calc(0.75rem + 0.39vw), 1.5rem);
  margin: 0 0 min(calc(1.7rem + 0.89vw), 3.4rem);
}
.content__text iframe {
  width: 100%;
  margin: 0 0 min(calc(2.3rem + 1.2vw), 4.6rem);
}
.content__text ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.content__text ul li {
  position: relative;
  padding-left: 1.6rem;
}
.content__text ul li:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  left: 0;
  top: 0.7rem;
  background: #212020;
}
.content__text ol,
.content__text ul {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.65rem + 0.34vw), 1.3rem) 0;
}
.breadcrumb__item:nth-child(n+2) {
  position: relative;
  padding: 0 0 0 min(calc(1.3rem + 0.68vw), 2.6rem);
}
@media (min-width: 992px) {
  .breadcrumb__item:nth-child(n+2) {
    padding: 0 0 0 min(calc(0.9rem + 0.47vw), 1.8rem);
  }
}
.breadcrumb__item:nth-child(n+2):before {
  content: "";
  display: inline-block;
  position: relative;
  left: min(calc(-0.45rem + -0.23vw), -0.9rem);
  transform: translate(0, -20%) rotate(45deg);
  border: 0.1rem solid #212020;
  border-width: 0.1rem 0.1rem 0 0;
  width: 0.4rem;
  height: 0.4rem;
  margin-bottom: 0.2rem;
}
.introduce__wrap {
  position: relative;
  padding: 0;
}
@media (min-width: 992px) {
  .introduce__wrap {
    padding: 0 2rem;
  }
}
.introduce__left {
  width: 100%;
  opacity: 0.65;
  position: relative;
/*&:before
      content ""
      position: absolute
      width: 100%
      height: 100%
      top 0
      right 0
      background: linear-gradient(90deg, transparent 0%, rgba(255,255,255,1) 100%);*/
}
@media (min-width: 992px) {
  .introduce__left {
    width: min(calc(34rem + 17.71vw), 68rem);
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .introduce__left {
    width: min(calc(41rem + 21.35vw), 82rem);
  }
}
.introduce__title {
  position: absolute;
  font-size: min(calc(1.4rem + 3.54vw), 8.2rem);
  left: 4%;
  right: 4%;
  top: min(calc(1.25rem + 0.65vw), 2.5rem);
  text-transform: uppercase;
  text-shadow: 0 0 0.8rem #fff;
  z-index: 1;
}
@media (min-width: 480px) {
  .introduce__title {
    right: auto;
  }
}
@media (min-width: 992px) {
  .introduce__title {
    width: min(calc(50rem + 26.04vw), 100rem);
    font-size: min(calc(1.4rem + 2.5vw), 6.2rem);
  }
}
@media (min-width: 1200px) {
  .introduce__title {
    left: 28.5%;
  }
}
.introduce__text {
  position: absolute;
  left: 4%;
  right: 4%;
  top: min(calc(12rem + 6.25vw), 24rem);
  font-size: min(calc(1.4rem + 1.56vw), 4.4rem);
  text-shadow: 0 0 0.8rem #fff;
  z-index: 1;
  font-weight: 600;
}
@media (min-width: 440px) {
  .introduce__text {
    top: min(calc(17rem + 8.85vw), 34rem);
  }
}
@media (min-width: 992px) {
  .introduce__text {
    width: min(calc(20rem + 10.42vw), 40rem);
    left: 51.5%;
    right: auto;
    top: min(calc(10.5rem + 5.47vw), 21rem);
    font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
    font-weight: 400;
  }
}
.introduce__link {
  position: absolute;
  left: 8%;
  top: min(calc(24.25rem + 12.63vw), 48.5rem);
  z-index: 1;
  font-size: min(calc(1.4rem + 1.98vw), 5.2rem);
}
@media (min-width: 440px) {
  .introduce__link {
    top: min(calc(29.25rem + 15.23vw), 58.5rem);
  }
}
@media (min-width: 992px) {
  .introduce__link {
    font-weight: 700;
    font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
    top: min(calc(24.25rem + 12.63vw), 48.5rem);
    font-size: inherit;
    left: 50.7%;
  }
}
.introduce__right {
  position: absolute;
  top: min(calc(11rem + 5.73vw), 22rem);
  left: 72.5%;
  padding: 0 min(calc(1.35rem + 0.7vw), 2.7rem) 0 0;
  display: none;
}
@media (min-width: 992px) {
  .introduce__right {
    display: block;
  }
}
.introduce__right:before {
  content: "";
  position: absolute;
  left: min(calc(1.3rem + 0.68vw), 2.6rem);
  top: min(calc(-1.1rem + -0.57vw), -2.2rem);
  bottom: min(calc(-1.1rem + -0.57vw), -2.2rem);
  border: 2px solid rgba(0,0,0,0.12);
  border-radius: 50%;
  right: 0;
  z-index: -1;
}
.introduce__rightImg {
  border-radius: min(calc(15rem + 7.81vw), 30rem);
  max-height: min(calc(27.5rem + 14.32vw), 55rem);
}
.imgStar {
  position: relative;
}
.imgStar:before {
  content: "";
  position: absolute;
  background: url("../images/icon_star.svg") no-repeat 0 0;
  background-size: contain;
  width: min(calc(9.8rem + 5.1vw), 19.6rem);
  height: min(calc(9.45rem + 4.92vw), 18.9rem);
}
.imgStar--topPage:before {
  right: min(calc(1.5rem + 0.78vw), 3rem);
  top: min(calc(-4.25rem + -2.21vw), -8.5rem);
}
@media (min-width: 992px) {
  .imgStar--topPage:before {
    top: min(calc(-6.25rem + -3.26vw), -12.5rem);
  }
}
.description {
  padding: min(calc(2.9rem + 1.51vw), 5.8rem) 0 min(calc(7.4rem + 3.85vw), 14.8rem);
}
@media (min-width: 992px) {
  .description {
    padding: min(calc(2.9rem + 1.51vw), 5.8rem) 0 min(calc(10.55rem + 5.49vw), 21.1rem);
  }
}
.description__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .description__wrap {
    gap: 0 2%;
  }
}
@media (min-width: 1200px) {
  .description__wrap {
    padding-right: min(calc(9rem + 4.69vw), 18rem);
  }
}
.description__title {
  font-size: min(calc(1.4rem + 1.15vw), 3.6rem);
  font-weight: 700;
  width: 100%;
  text-transform: uppercase;
}
.description__text {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem) 0;
  line-height: 1.35;
}
@media (min-width: 760) {
  .description__text {
    width: 49%;
  }
}
@media (min-width: 992px) {
  .description__text {
    width: min(calc(29.05rem + 15.13vw), 58.1rem);
  }
}
.description__text:nth-child(2) {
  padding: min(calc(2rem + 1.04vw), 4rem) 0 0;
}
.description__text:nth-child(3) {
  padding: min(calc(1rem + 0.52vw), 2rem) 0 0;
}
.description__text:nth-child(3):before {
  content: "";
  position: absolute;
  left: min(calc(-7.8rem + -4.06vw), -15.6rem);
  top: min(calc(-2.3rem + -1.2vw), -4.6rem);
  background: rgba(255,207,207,0.27);
  width: min(calc(23rem + 11.98vw), 46rem);
  height: min(calc(23rem + 11.98vw), 46rem);
  border-radius: 50%;
  z-index: -1;
}
.description__box {
  padding: 0 0 min(calc(2.5rem + 1.3vw), 5rem);
}
@media (min-width: 992px) {
  .description__box {
    padding: min(calc(0.45rem + 0.23vw), 0.9rem) 0 0 min(calc(5rem + 2.6vw), 10rem);
  }
}
.description__box:before {
  left: 0;
  top: min(calc(-0.5rem + -0.26vw), -1rem);
  transform: translate(-50%, -50%);
}
.description__link {
  margin: min(calc(3.2rem + 1.67vw), 6.4rem) 0 0 min(calc(0.85rem + 0.44vw), 1.7rem);
}
.catalog {
  padding: 0 0 min(calc(5.5rem + 2.86vw), 11rem);
}
@media (min-width: 1480px) {
  .catalog {
    padding: 0 0 min(calc(9rem + 4.69vw), 18rem);
  }
}
.catalog--inner {
  padding: min(calc(1.5rem + 0.78vw), 3rem) 0 min(calc(5.5rem + 2.86vw), 11rem);
}
.catalog__wrap {
  text-align: center;
}
.catalog__box {
  padding: min(calc(4rem + 2.08vw), 8rem) 0 min(calc(4rem + 2.08vw), 8rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: min(calc(2.45rem + 1.28vw), 4.9rem) min(calc(0.9rem + 0.47vw), 1.8rem);
}
@media (min-width: 992px) {
  .catalog__box {
    padding: min(calc(4rem + 2.08vw), 8rem) 0 min(calc(5rem + 2.6vw), 10rem);
    justify-content: space-between;
  }
}
.catalog__box:after {
  content: "";
  height: 0;
  width: 100%;
  max-width: min(calc(17rem + 8.85vw), 34rem);
}
.catalog__box--inner {
  padding: min(calc(4rem + 2.08vw), 8rem) 0;
}
@media (min-width: 992px) {
  .catalog__box--inner {
    padding: min(calc(4rem + 2.08vw), 8rem) 0;
  }
}
.catalog__linkShowAll span {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid #212020;
  margin-left: 1rem;
  transform: translate(0, -15%);
}
.category {
  border-radius: 1.5rem;
  border: 1px solid #bbb;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  font-family: TTCommons, Arial, sans-serif;
}
@media (min-width: 480px) {
  .category {
    width: min(calc(17rem + 8.85vw), 34rem);
  }
}
.category__text {
  height: min(calc(3.8rem + 1.98vw), 7.6rem);
}
.category__imgWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: min(calc(14.25rem + 7.42vw), 28.5rem);
  padding: 0 0 min(calc(0.75rem + 0.39vw), 1.5rem);
}
.category__img {
  max-height: 100%;
}
.category__small {
  display: block;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  font-weight: 400;
}
.solutions {
  padding: min(calc(5.5rem + 2.86vw), 11rem) 0 0;
}
.solutions__slider {
  padding: min(calc(4rem + 2.08vw), 8rem) 0 0;
}
@media (min-width: 820px) {
  .solutions__slider {
    padding: min(calc(7rem + 3.65vw), 14rem) 0 0;
  }
}
.sliderSolutions {
  position: relative;
}
.sliderSolutions__box {
  position: relative;
  overflow: hidden;
  touch-action: pan-y;
}
@media (min-width: 1280px) {
  .sliderSolutions__box {
    border-radius: min(calc(15rem + 7.81vw), 30rem);
  }
}
.sliderSolutions__inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  transform: translate3d(0px, 0, 0);
  cursor: e-resize;
}
.sliderSolutions__item {
  flex-shrink: 0;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sliderSolutions__btn {
  position: absolute;
  width: 3.3rem;
  height: 3.3rem;
  top: 50%;
  z-index: 2;
  background: url("../images/icon_arrow.svg") no-repeat 0 0;
}
.sliderSolutions__btn--left {
  left: 0;
  transform: translate(0, -50%);
}
.sliderSolutions__btn--right {
  right: 0;
  transform: translate(0, -50%) rotate(180deg);
}
.sliderSolutions__img {
  height: min(calc(15.65rem + 8.15vw), 31.3rem);
  border-radius: min(calc(7.5rem + 3.91vw), 15rem) min(calc(7.5rem + 3.91vw), 15rem) 0 0;
}
.sliderSolutions__item:nth-child(even) .sliderSolutions__img {
  border-radius: min(calc(7.8rem + 4.06vw), 15.6rem);
}
.sliderSolutions__label {
  padding: min(calc(1.3rem + 0.68vw), 2.6rem) 0 0;
  text-transform: uppercase;
}
.feedback {
  padding: min(calc(9rem + 4.69vw), 18rem) 0 0;
}
.feedback__wrap {
  display: flex;
  justify-content: space-between;
  gap: min(calc(1.5rem + 0.78vw), 3rem);
  flex-direction: column;
}
@media (min-width: 640px) {
  .feedback__wrap {
    flex-direction: row;
  }
}
@media (min-width: 640px) {
  .feedback__column:nth-child(1) {
    width: min(calc(23.25rem + 12.11vw), 46.5rem);
  }
}
@media (min-width: 640px) {
  .feedback__column:nth-child(2) {
    width: min(calc(20rem + 10.42vw), 40rem);
    margin: 0 auto;
  }
}
.feedback__column:nth-child(3) {
  display: none;
}
@media (min-width: 840px) {
  .feedback__column:nth-child(3) {
    display: block;
  }
}
.feedback__title,
.feedback__text {
  font-weight: 700;
  line-height: 1.6;
  text-transform: uppercase;
}
.feedback__title {
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
}
.feedback__title--pink {
  position: relative;
}
.feedback__title--pink:before {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 0 min(calc(15rem + 7.81vw), 30rem) min(calc(15rem + 7.81vw), 30rem) 0;
  background: rgba(255,207,207,0.27);
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: min(calc(-2.5rem + -1.3vw), -5rem) min(calc(-0.7rem + -0.36vw), -1.4rem) min(calc(-0.5rem + -0.26vw), -1rem) min(calc(-1rem + -0.52vw), -2rem);
}
@media (min-width: 640px) {
  .feedback__title--pink:before {
    margin: min(calc(-1.6rem + -0.83vw), -3.2rem) min(calc(-1rem + -0.52vw), -2rem);
  }
}
@media (min-width: 992px) {
  .feedback__title--pink:before {
    left: min(calc(-13.4rem + -6.98vw), -26.8rem);
    margin: min(calc(-1.6rem + -0.83vw), -3.2rem);
  }
}
@media (min-width: 1280) {
  .feedback__title--pink:before {
    margin: min(calc(-3.4rem + -1.77vw), -6.8rem);
  }
}
.feedback__text {
  padding: 0 0 min(calc(1.4rem + 0.73vw), 2.8rem);
}
.feedback__img {
  width: min(calc(19.85rem + 10.34vw), 39.7rem);
  border-radius: min(calc(14rem + 7.29vw), 28rem);
}
.form__input,
.form__textarea {
  background: none;
  width: 100%;
  padding: min(calc(0.6rem + 0.31vw), 1.2rem) min(calc(0.1rem + 0.05vw), 0.2rem);
  border: none;
  border-bottom: 1px solid #212020;
  margin: 0 0 min(calc(1.7rem + 0.89vw), 3.4rem);
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.form__textarea {
  min-height: min(calc(5.4rem + 2.81vw), 10.8rem);
  max-width: 100%;
  margin: 0 0 min(calc(3.6rem + 1.88vw), 7.2rem);
}
.form__submit {
  background: none;
  border: none;
  font-weight: 700;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  color: #212020;
  transition: opacity 0.32s;
}
.form__submit.disabled {
  opacity: 0.35;
  cursor: default;
}
.form__loader,
.form__answer {
  display: none;
}
.cart {
  padding: 0 0 min(calc(4.1rem + 2.14vw), 8.2rem);
}
.cart__items {
  padding: min(calc(4.4rem + 2.29vw), 8.8rem) 0 min(calc(2.7rem + 1.41vw), 5.4rem);
  font-family: TTCommons, Arial, sans-serif;
}
.cart__item {
  display: flex;
  border: 1px solid #aea9a9;
  padding: min(calc(1.3rem + 0.68vw), 2.6rem) min(calc(1rem + 0.52vw), 2rem);
  min-height: min(calc(11.25rem + 5.86vw), 22.5rem);
  gap: 0 min(calc(1.25rem + 0.65vw), 2.5rem);
  flex-wrap: wrap;
}
@media (min-width: 480px) {
  .cart__item {
    flex-wrap: nowrap;
  }
}
@media (min-width: 680px) {
  .cart__item {
    padding: min(calc(1.3rem + 0.68vw), 2.6rem) min(calc(2.5rem + 1.3vw), 5rem) min(calc(1.3rem + 0.68vw), 2.6rem) min(calc(1rem + 0.52vw), 2rem);
  }
}
.cart__item:nth-child(n+2) {
  border-top: 0;
}
.cart__column {
  display: flex;
  align-content: flex-start;
}
.cart__column:nth-child(1) {
  justify-content: center;
  width: 100%;
}
@media (min-width: 480px) {
  .cart__column:nth-child(1) {
    width: 16.5%;
    flex-shrink: 0;
  }
}
.cart__column:nth-child(2) {
  flex-direction: column;
  gap: min(calc(0.4rem + 0.21vw), 0.8rem) min(calc(1.1rem + 0.57vw), 2.2rem);
  width: 86%;
}
@media (min-width: 480px) {
  .cart__column:nth-child(2) {
    width: auto;
  }
}
@media (min-width: 680px) {
  .cart__column:nth-child(2) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.cart__column:nth-child(3) {
  margin-left: auto;
}
.cart__label {
  text-transform: uppercase;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  font-weight: 500;
  width: 100%;
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
}
@media (min-width: 680px) {
  .cart__label {
    padding: min(calc(0.45rem + 0.23vw), 0.9rem) 0 min(calc(2rem + 1.04vw), 4rem);
  }
}
.cart__color,
.cart__count,
.cart__price {
  display: flex;
  align-items: center;
  gap: 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
}
@media (min-width: 680px) {
  .cart__color,
  .cart__count,
  .cart__price {
    font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  }
}
.cart__attr {
  display: flex;
  justify-content: center;
  padding: min(calc(0.1rem + 0.05vw), 0.2rem) 0 0;
  width: 25.5%;
  gap: min(calc(0.4rem + 0.21vw), 0.8rem);
  flex-direction: column;
}
.cart__count {
  width: 35.5%;
}
.cart__countLabel {
  padding: min(calc(0.1rem + 0.05vw), 0.2rem) 0 0;
}
.cart__countInput {
  border: 1px solid #aea9a9;
  border-radius: 30px;
  padding: min(calc(0.25rem + 0.13vw), 0.5rem) min(calc(0.7rem + 0.36vw), 1.4rem) 0;
  height: 3.6rem;
  width: 8rem;
}
.cart__price {
  padding: min(calc(1rem + 0.52vw), 2rem) 0 0;
  font-weight: 500;
}
@media (min-width: 680px) {
  .cart__price {
    padding: min(calc(0.1rem + 0.05vw), 0.2rem) 0 0;
  }
}
.cart__priceValue {
  color: #fa1919;
  font-weight: 500;
}
.cart__delete {
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
}
@media (min-width: 680px) {
  .cart__delete {
    margin: min(calc(0.55rem + 0.29vw), 1.1rem) 0 0;
  }
}
.cart__delete:before,
.cart__delete:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.01rem;
  background: rgba(0,0,0,0.7);
  margin: 50% 0 0;
  transition: all 0.32s;
}
.cart__delete:before {
  left: 0;
  transform: rotate(45deg);
}
.cart__delete:after {
  right: 0;
  transform: rotate(-45deg);
}
.cart__delete:hover {
  background: none !important;
}
.cart__delete:hover:before,
.cart__delete:hover:after {
  background: #fa1919;
}
.cart__bottomBox {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: min(calc(2.2rem + 1.15vw), 4.4rem) min(calc(1rem + 0.52vw), 2rem);
}
.cart__linkCatalog {
  position: relative;
  padding: 0 0 0 min(calc(1.6rem + 0.83vw), 3.2rem);
  order: 2;
}
@media (min-width: 480px) {
  .cart__linkCatalog {
    order: 0;
  }
}
.cart__linkCatalog:before {
  content: "";
  position: absolute;
  width: min(calc(0.45rem + 0.23vw), 0.9rem);
  height: min(calc(0.45rem + 0.23vw), 0.9rem);
  top: 50%;
  left: 0;
  transform: rotate(45deg) translate(0, -50%);
  border: 0.1rem solid #000;
  border-width: 0 0 0.1rem 0.1rem;
}
.cart__itog {
  width: 100%;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
}
@media (min-width: 480px) {
  .cart__itog {
    max-width: 22.5rem;
  }
}
@media (min-width: 992px) {
  .cart__itog {
    max-width: 36.5rem;
  }
}
.cart__itogValue,
.cart__itogLabel {
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
}
.cart__itogValue {
  color: #fa1919;
  margin-left: auto;
}
.cart__itogBreak {
  height: 0.1rem;
  width: 100%;
  background: #000;
  margin: min(calc(0.8rem + 0.42vw), 1.6rem) 0 min(calc(1.45rem + 0.76vw), 2.9rem);
}
.cart__linkOffer {
  display: flex;
  justify-content: center;
  background: #f1f2ff;
  height: min(calc(2.75rem + 1.43vw), 5.5rem);
  border-radius: 3rem;
  font-weight: 600;
  width: 100%;
  padding: min(calc(0.75rem + 0.39vw), 1.5rem) 0 0;
}
.cart__img {
  margin: 0 0 min(calc(1.5rem + 0.78vw), 3rem);
  object-fit: contain;
  max-height: 14rem;
}
@media (min-width: 480px) {
  .cart__img {
    margin: 0;
    max-height: none;
  }
}
.recommend {
  padding: min(calc(2.4rem + 1.25vw), 4.8rem) 0 0;
}
.recommend__title {
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  font-weight: 500;
}
.recommend__items {
  padding: min(calc(1.6rem + 0.83vw), 3.2rem) 0 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: min(calc(1.5rem + 0.78vw), 3rem) min(calc(1.5rem + 0.78vw), 3rem);
}
@media (min-width: 740px) {
  .recommend__items {
    justify-content: space-between;
  }
}
.recommend__items:after {
  content: "";
  height: 0;
  width: 100%;
  max-width: min(calc(15.2rem + 7.92vw), 30.4rem);
}
.product {
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 1.5rem;
  padding: 1.4rem;
  text-align: center;
}
@media (min-width: 480px) {
  .product {
    max-width: min(calc(15.2rem + 7.92vw), 30.4rem);
  }
}
.product__imgWrap {
  display: flex;
  justify-content: center;
  height: min(calc(11.75rem + 6.12vw), 23.5rem);
  padding: min(calc(0.4rem + 0.21vw), 0.8rem) 0 min(calc(0.5rem + 0.26vw), 1rem);
}
.product__label {
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  min-height: min(calc(2.5rem + 1.3vw), 5rem);
  padding: min(calc(0.2rem + 0.1vw), 0.4rem) min(calc(0.55rem + 0.29vw), 1.1rem) min(calc(0.55rem + 0.29vw), 1.1rem);
}
.product__price {
  font-family: TTCommons, Arial, sans-serif;
  font-weight: 500;
  margin: 0 0 min(calc(0.7rem + 0.36vw), 1.4rem);
  text-align: center;
  color: #fa1919;
}
.product__addCart {
  display: flex;
  justify-content: center;
  background: #f1f2ff;
  border-radius: 3rem;
  height: 3.6rem;
  padding: 0.8rem 0 0;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  font-weight: 600;
  width: 100%;
  max-width: 15.5rem;
  margin: 0 auto;
}
.good__columns {
  display: flex;
  gap: min(calc(0.75rem + 0.39vw), 1.5rem);
  flex-direction: column;
}
@media (min-width: 480px) {
  .good__columns {
    flex-direction: row;
  }
}
.good__topBox {
  padding: 0 0 min(calc(6rem + 3.13vw), 12rem);
}
@media (min-width: 480px) {
  .good__topBox {
    display: grid;
    grid-template-columns: 25% 75%;
  }
}
@media (min-width: 880px) {
  .good__topBox {
    grid-template-columns: min(calc(21.75rem + 11.33vw), 43.5rem) auto;
  }
}
.good__box2 {
  padding: 0 0 min(calc(1.75rem + 0.91vw), 3.5rem);
}
@media (min-width: 480px) {
  .good__box2 {
    padding: 0 min(calc(1.25rem + 0.65vw), 2.5rem) 0 0;
    grid-row: 1/3;
  }
}
.good__imgWrap {
  display: inline-flex;
  justify-content: center;
  border: 1px solid #b1b1b1;
  padding: min(calc(1.5rem + 0.78vw), 3rem) min(calc(2.5rem + 1.3vw), 5rem);
}
.good__label {
  font-family: TTCommons, Arial, sans-serif;
  font-weight: 600;
  font-size: min(calc(1.4rem + 1.04vw), 3.4rem);
  padding: 0 0 min(calc(2.25rem + 1.17vw), 4.5rem);
}
@media (min-width: 480px) {
  .good__label {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
    font-weight: 500;
  }
}
.good__spec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: TTCommons, Arial, sans-serif;
  gap: min(calc(1rem + 0.52vw), 2rem) 0;
  padding: 0 0 min(calc(2.9rem + 1.51vw), 5.8rem);
}
@media (min-width: 480px) {
  .good__spec {
    max-width: min(calc(36rem + 18.75vw), 72rem);
  }
}
.good__specItem {
  width: 50%;
  max-width: min(calc(17.5rem + 9.11vw), 35rem);
  padding: 0 min(calc(1rem + 0.52vw), 2rem) 0 0;
}
.good__price {
  font-family: TTCommons, Arial, sans-serif;
  font-weight: 500;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  color: #fa1919;
  padding: 0 0 min(calc(0.7rem + 0.36vw), 1.4rem);
}
.good__bottomLine {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.5rem + 0.78vw), 3rem) 0;
}
@media (min-width: 480px) {
  .good__bottomLine {
    flex-direction: row;
  }
}
.good__count {
  display: flex;
  gap: 0 min(calc(1.2rem + 0.63vw), 2.4rem);
  width: 100%;
  max-width: min(calc(18.5rem + 9.64vw), 37rem);
}
.good__countLabel {
  font-family: TTCommons, Arial, sans-serif;
  padding: 0.8rem 0 0;
}
.good__countInput {
  font-family: TTCommons, Arial, sans-serif;
  border: 1px solid #aea9a9;
  border-radius: 3rem;
  padding: 0.3rem 1.4rem 0;
  height: 3.6rem;
  width: 8rem;
}
.good__addCart {
  display: block;
  border-radius: 3rem;
  height: 3.6rem;
  padding: 0.8rem 0 0;
  text-align: center;
  width: 15.5rem;
  background: #f1f2ff;
  font-weight: 600;
}
.good__bottomBox {
  display: flex;
  gap: min(calc(3.3rem + 1.72vw), 6.6rem);
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 880px) {
  .good__bottomBox {
    flex-direction: row;
  }
}
.good__tabs {
  border: 1px solid #aea9a9;
  padding: 0 min(calc(0.75rem + 0.39vw), 1.5rem) min(calc(1.8rem + 0.94vw), 3.6rem);
  width: 100%;
}
@media (min-width: 480px) {
  .good__tabs {
    padding: 0 min(calc(2.9rem + 1.51vw), 5.8rem) min(calc(1.8rem + 0.94vw), 3.6rem);
  }
}
@media (min-width: 880px) {
  .good__tabs {
    width: 59.35%;
  }
}
.good__tabsTopBox {
  display: flex;
}
.good__tabsBtn {
  padding: min(calc(0.75rem + 0.39vw), 1.5rem) min(calc(1.7rem + 0.89vw), 3.4rem);
  transition: all 0.32s;
}
.good__tabsBtn.open {
  cursor: default;
  color: #212020;
  background: rgba(252,199,199,0.3);
}
.good__text {
  display: none;
  flex-direction: column;
  gap: min(calc(1.5rem + 0.78vw), 3rem) 0;
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  line-height: 1.44;
  width: 100%;
  padding: min(calc(1.2rem + 0.63vw), 2.4rem) 0 0;
}
@media (min-width: 880px) {
  .good__text {
    max-width: min(calc(36.7rem + 19.11vw), 73.4rem);
  }
}
.good__text.open {
  display: flex;
}
.good__follow {
  width: 100%;
}
@media (min-width: 880px) {
  .good__follow {
    width: 37%;
  }
}
.follow {
  display: flex;
  gap: min(calc(0.4rem + 0.21vw), 0.8rem);
  flex-direction: column;
}
.follow__title {
  text-transform: uppercase;
  padding: 0 0 min(calc(1rem + 0.52vw), 2rem);
  font-weight: 700;
}
.follow__item {
  border: 0.1rem solid #bbb;
  border-radius: 1.5rem;
  padding: 1rem 2.5rem 1rem 1rem;
  width: 100%;
  display: flex;
  gap: min(calc(1.25rem + 0.65vw), 2.5rem);
}
.follow__column:nth-child(1) {
  width: 100%;
  max-width: min(calc(12.25rem + 6.38vw), 24.5rem);
  max-height: min(calc(7.75rem + 4.04vw), 15.5rem);
  display: flex;
  justify-content: center;
}
.follow__img {
  max-height: 100%;
  object-fit: contain;
}
.follow__label {
  display: block;
  padding: min(calc(1rem + 0.52vw), 2rem) 0 min(calc(0.75rem + 0.39vw), 1.5rem);
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.follow__price {
  font-family: TTCommons, Arial, sans-serif;
  font-weight: 500;
  color: #fa1919;
  padding: 0 0 min(calc(0.7rem + 0.36vw), 1.4rem);
}
.follow__addCard {
  display: block;
  border-radius: 3rem;
  height: 3.6rem;
  padding: 0.7rem 0 0;
  text-align: center;
  width: 15.5rem;
  background: #f1f2ff;
  font-weight: 600;
}
.products__columns {
  border-top: 1px solid #999;
  margin: min(calc(3.2rem + 1.67vw), 6.4rem) 0 0;
  padding: min(calc(1.4rem + 0.73vw), 2.8rem) 0 0;
  display: flex;
  flex-direction: column;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem);
}
@media (min-width: 680px) {
  .products__columns {
    flex-direction: row;
    height: 19rem;
  }
}
@media (min-width: 880px) {
  .products__columns {
    height: 24rem;
  }
}
.products__columns.open {
  height: auto;
}
.products__filters {
  width: 100%;
  max-width: 36.5rem;
  order: 2;
}
@media (min-width: 680px) {
  .products__filters {
    order: 1;
  }
}
.products__orders {
  order: 1;
}
@media (min-width: 680px) {
  .products__orders {
    margin-left: auto;
    order: 2;
  }
}
.products__list {
  padding: min(calc(1.6rem + 0.83vw), 3.2rem) 0 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: min(calc(1.5rem + 0.78vw), 3rem) min(calc(1.5rem + 0.78vw), 3rem);
}
@media (min-width: 740px) {
  .products__list {
    justify-content: space-between;
  }
}
.products__list:after {
  content: "";
  height: 0;
  width: 100%;
  max-width: min(calc(15.2rem + 7.92vw), 30.4rem);
}
.products__pages ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.9rem;
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  padding: min(calc(1.2rem + 0.63vw), 2.4rem) 0 0 !important;
  margin: 0 !important;
}
@media (min-width: 992px) {
  .products__pages ul {
    padding: min(calc(3.7rem + 1.93vw), 7.4rem) 0 0 !important;
  }
}
.products__pages ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.products__pages ul .page-numbers {
  border-radius: 3rem;
  border: 0.1rem solid #d3d3d3;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.5rem 0.6rem 0;
  text-align: center;
}
.products__pages ul .page-numbers.current {
  background: #f1f2ff;
}
.pages {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.9rem;
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  padding: min(calc(1.2rem + 0.63vw), 2.4rem) 0 0;
}
@media (min-width: 992px) {
  .pages {
    padding: min(calc(3.7rem + 1.93vw), 7.4rem) 0 0;
  }
}
.pages__num {
  border-radius: 3rem;
  border: 0.1rem solid #d3d3d3;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem 0.6rem 0;
}
.pages__num.active {
  background: #f1f2ff;
}
.pages__prev,
.pages__next {
  width: 0.9rem;
  height: 0.9rem;
  border: 0.1rem solid #212020;
  transform: rotate(45deg);
  margin-top: -0.1rem;
  transition: all 0.32s;
}
.pages__prev:hover,
.pages__next:hover {
  border-color: #fa1919;
}
.pages__prev {
  border-width: 0 0 0.1rem 0.1rem;
}
.pages__next {
  border-width: 0.1rem 0.1rem 0 0;
}
.order {
  display: flex;
  gap: 0 1.2rem;
  align-items: center;
  font-family: TTCommons, Arial, sans-serif;
}
.order__label {
  padding: 0.4rem 0 0;
}
.order__select {
  position: relative;
  height: 3.5rem;
  cursor: pointer;
  border: 0.1rem solid #ddd;
  border-radius: 3rem;
  appearance: none;
  font-family: TTCommons, Arial, sans-serif;
  padding: 0.5rem 1rem 0;
  text-align: center;
  min-width: 15.5rem;
}
.order__form {
  margin: 0 !important;
  float: none !important;
}
.page__box {
  border-top: 1px solid #aea9a9;
  margin: min(calc(4.8rem + 2.5vw), 9.6rem) 0 0;
  padding: min(calc(2.5rem + 1.3vw), 5rem) 0 0;
}
.page__text {
  max-width: 96rem;
  margin: 0 auto;
}
.contacts__columns {
  gap: min(calc(3.2rem + 1.67vw), 6.4rem) 10%;
}
@media (min-width: 840px) {
  .contacts__columns {
    gap: 0 10%;
  }
}
.contacts__text {
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
}
@media (min-width: 840px) {
  .contacts__text {
    min-height: min(calc(9.6rem + 5vw), 19.2rem);
  }
}
.contacts__address,
.contacts__phoneWrap,
.contacts__phone {
  display: flex;
  gap: min(calc(0.6rem + 0.31vw), 1.2rem);
}
.contacts__address {
  align-items: flex-start;
}
.contacts__phone {
  gap: min(calc(0.4rem + 0.21vw), 0.8rem);
}
@media (min-width: 840px) {
  .contacts__phoneImg,
  .contacts__addressImg {
    margin: 0.3rem 0 0;
  }
}
.contacts__phoneImg {
  width: 1.5rem;
  height: 1.5rem;
}
@media (min-width: 840px) {
  .contacts__phoneImg {
    width: 2.2rem;
    height: 2.2rem;
  }
}
.contacts__iframe {
  width: 100%;
}
.news__items {
  display: flex;
  flex-wrap: wrap;
  margin: min(calc(4.8rem + 2.5vw), 9.6rem) 0 0;
  padding: min(calc(4rem + 2.08vw), 8rem) 0;
  border-top: 1px solid #aea9a9;
  justify-content: center;
  gap: 5.5rem 2rem;
}
@media (min-width: 1240px) {
  .news__items {
    justify-content: space-between;
  }
}
.news__items:after {
  content: "";
}
.news__items:after,
.news__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 580px) {
  .news__items:after,
  .news__item {
    width: min(calc(17rem + 8.85vw), 34rem);
  }
}
.news__itemImgWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 min(calc(0.75rem + 0.39vw), 1.5rem);
}
@media (min-width: 580px) {
  .news__itemImgWrap {
    height: min(calc(11rem + 5.73vw), 22rem);
  }
}
.news__itemImg {
  max-height: 100%;
}
.news__itemTitle {
  font-weight: 600;
  font-family: TTCommons, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0.63vw), 2.6rem);
}
.news__itemText {
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  padding: min(calc(0.4rem + 0.21vw), 0.8rem) 0;
}
.news__itemDate {
  color: #7a7a7a;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
}
.whatsapp {
  display: block;
  width: 3rem;
  height: 3rem;
  margin: -0.2rem 0 0;
  transition: all 0.32s;
}
.whatsapp:hover {
  transform: scale(1.2);
}
.desktopOnly {
  display: none;
}
@media (min-width: 992px) {
  .desktopOnly {
    display: block;
  }
}
.filters {
  border: 1px solid #bbb;
  box-shadow: 0 0.4rem 0.4rem rgba(0,0,0,0.25);
  border-radius: 1.5rem;
  padding: 1.2rem 1.4rem 0 1.4rem;
  font-family: TTCommons, Arial, sans-serif;
  position: relative;
  background: #fff;
  z-index: 1;
  position: relative;
  overflow: hidden;
  height: 21rem;
  transition: height 0.32s;
/*
  &__box
    padding: 0 0 countVW(21)

  &__price
    display flex
    justify-content: space-between
    gap 0 10%

  &__priceInput
    border-radius: 3rem;
    border: .1rem solid #BBB;
    padding .2rem 1.1rem 0
    width 45%
    height: 3.5rem
    
  &__item
    display: flex
    align-items: center
    transition: all .32s

    &:hover
      color #fa1919

  &__checkInput,
  &__checkLabel
    cursor pointer

  &__checkInput
    border: .1rem solid #ADADAD;
    width: 1.1rem
    height: 1.1rem

  &__checkLabel
    padding .3rem 0 0 1.2rem

  &__buttons
    display flex
    justify-content: space-between
    padding 0 0 countVW(24)
    gap 10%

  &__button
    border-radius: 3rem;
    width 45%
    max-width 15.5rem
    height 3.6rem
    display: flex
    justify-content: center
    align-items: center
    padding: .3rem 0 0

    &--show
      background: #F1F2FF;

    &--reset
      border 1px solid #AEA9A9
      background #AEA9A9
      color #fff
      transition: all .32s

      &:hover
        background #fff
        color #AEA9A9

  &__slider
    position: relative
    top: 1.8rem
    height: 2.2rem

  &__sliderLineGray,
  &__sliderLineActive
    position: absolute
    height: .2rem
    top: .4rem

  &__sliderLineGray
    width 100%
    background: #bbb

  &__sliderLineActive
    width 80%
    background: #FCC7C7

    &:before,
    &:after
      content: ""
      position: absolute
      width 1.1rem
      height 1.1rem
      border-radius: 50%
      top: 50%
      transform translate(0,-50%)
      background: #FCC7C7
      cursor: pointer

    &:before
      left 0

    &:after
      right 0
  */
}
.filters.open {
  height: auto;
}
.filters__title,
.filters__label {
  font-weight: 500;
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
}
.filters__crop {
  display: none;
  overflow: hidden;
}
.filters__cropBox {
  position: absolute;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  text-align: center;
  padding: 1rem;
  background: #fff;
}
.filters__cropLink {
  position: relative;
  padding: 0 1.5rem;
}
.filters__cropLink:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transition: all 0.35s;
  transform: translate(0, -50%);
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.6rem solid #212020;
}
.filters__cropLink.open:before {
  transform: translate(0, -50%) rotate(180deg);
}
.img-loader {
  stroke: #545454;
}
.checkout input,
.checkout textarea,
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 0.6rem 0.6rem 0.2rem;
}
.woocommerce form .show-password-input,
.woocommerce-page form .show-password-input {
  top: 50% !important;
  transform: translate(0, -50%) !important;
}
.woocommerce form .show-password-input::after,
.woocommerce-page form .show-password-input::after {
  line-height: 2;
}
.woocommerce fieldset {
  border: 1px solid #aaa;
  border-radius: 1.5rem;
  padding: 1.2rem;
  margin-bottom: 2.5rem;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  border-radius: 1.5rem;
}
.woof h4 {
  font-weight: 500;
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
}
.woof_sid_auto_shortcode {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.woof_sid_auto_shortcode .woof_container {
  width: 100% !important;
  padding: 0 0 min(calc(1.05rem + 0.55vw), 2.1rem) !important;
  float: none !important;
  margin: 0 !important;
}
.woof_sid_auto_shortcode .woof_container.woof_price_filter {
  padding: 0 0 min(calc(2.25rem + 1.17vw), 4.5rem) !important;
}
.woof_sid_auto_shortcode .woof_container_inner {
  padding: 0 !important;
}
.woocommerce .widget_price_filter .ui-slider-horizontal {
  height: 2px !important;
}
.woocommerce .widget_price_filter .price_slider {
  margin-bottom: 0 !important;
}
.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #bbb !important;
}
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #fcc7c7 !important;
  border-radius: 0 !important;
}
.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  background-color: #fcc7c7 !important;
  border-radius: 50% !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
  border: none !important;
}
.woocommerce .widget_price_filter .ui-slider {
  margin-top: 0.8rem !important;
  margin-bottom: 0.4rem !important;
}
.woocommerce .widget_price_filter .price_slider_amount {
  position: relative;
}
.woof_price_search_container .price_slider_amount .price_label {
  font-size: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.woof_price_search_container .price_slider_amount .price_label span {
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
}
.woof_price_search_container .price_slider_amount .price_label .to {
  float: right;
}
.woof .woof_list li {
  margin: 0 !important;
  padding: 0 !important;
}
.woof_list label {
  padding-bottom: 0 !important;
}
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woof_reset_button_2 {
  border-radius: 3rem !important;
  width: 15.5rem !important;
  height: 3.6rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.3rem 0 0 !important;
  border: 1px solid #aea9a9 !important;
  background: #aea9a9 !important;
  color: #fff !important;
  transition: all 0.32s !important;
}
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woof_reset_button_2:hover {
  background: #fff !important;
  color: #aea9a9 !important;
}
.woof_reset_button_2 {
  font-weight: 600;
  padding: 0 !important;
}
.woof .widget_price_filter .price_slider_amount button.button {
  float: left !important;
  margin: 4rem 0 0 !important;
  background: #f1f2ff !important;
  color: #000 !important;
  font-weight: 400;
}
.woof_submit_search_form_container {
  margin: 0 0 3rem !important;
}
.woof_products_top_panel_ul {
  display: none;
}
.woocommerce-message {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #b1b1b1;
}
.cart .woocommerce-info,
.cart .woocommerce-message,
.products .woocommerce-message {
  margin-top: 4.5rem !important;
}
.woocommerce-info,
.good .woocommerce-message {
  margin-bottom: 4.5rem !important;
  background: rgba(241,242,255,0.5);
  border: 1px solid #b1b1b1;
}
.woocommerce a.button.wc-backward {
  display: none !important;
}
.woocommerce a.button.wc-forward {
  width: 22rem !important;
  order: 1;
}
.woocommerce-thankyou-order-received {
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
}
.woocommerce ul.order_details li {
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem) !important;
}
.woocommerce ul.order_details li:before {
  display: none;
}
