html
  height 100%

.hide
  display none

.hidden
  overflow hidden

.clearfix
  zoom 1

.clearfix:after
  clear both
  content ''
  display block
  height 0
  visibility hidden

.nobr
  white-space nowrap

.italic
  font-style italic

.floatLeft
  float left

.floatRight
  float right

.alignLeft
  text-align left

.alignRight
  text-align right

.alignCenter
  text-align center

a
  text-decoration none
  color inherit

p
  margin 0
  
img
  display block
  max-width 100%
    
strong
  font-weight 600